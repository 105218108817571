<script setup lang="ts">
import { useCookieConsentStore } from '~/stores/useCookieConsentStore'

const ccStore = useCookieConsentStore()
const route = useRoute()
const { $viewport } = useNuxtApp()

const showAd = computed(() => {
  return (
    ccStore.isTargetingAccepted &&
    $viewport.isLessThan('xl') &&
    (route.name === 'radio-id' || route.name === 'podcast-podcastId') &&
    (route.params.id === 'development' ||
      route.params.id === 'sheil-feb-2021' ||
      route.params.podcastId === 'new-podcast-2-3')
  )
})
</script>

<template>
  <div v-if="showAd" class="flex justify-center">
    <GoogleAd
      ad-unit-id="div-gpt-ad-1730815690760-0"
      ad-unit-path="/21869305879/ZenoFM_300x250"
      :width="300"
      :height="250"
      :targeting="{ page_url: route.path }"
    />
  </div>
</template>

<style scoped></style>
